<template>
  <footer class="bg-black text-white py-8 lg:py-4">
    <Container>
      <div class="lg:flex lg:justify-between lg:items-end">
        <LogoFooter />
        <nav class="flex mt-4 lg:mt-0 lg:align-bottom">
          <NuxtLink 
          v-for="link in footerLinks" 
          :to="link.slug"
          class="underline text-sm md:text-base mr-3 md:mr-6 lg:ml-4 lg:mr-0 lg:px-4"
          >
            <span class="xl:hidden">{{ link.title }}</span>
            <span class="hidden xl:inline">{{ link.titleLong }}</span>
          </NuxtLink>
        </nav>
      </div>
    </Container>
  </footer>
</template>

<script setup>
const user = useSupabaseUser()
const userStore = useUserStore()
const { profile } = storeToRefs(userStore)

const footerLinks = [
  {
    title: 'Privacy',
    slug: '/policy/privacy',
    titleLong: 'Privacy Policy'
  },
  {
    title: 'Cookies',
    slug: '/policy/cookies',
    titleLong: 'Cookie Policy'
  },
  {
    title: 'Terms',
    slug: '/policy/terms',
    titleLong: 'Terms of Service'
  },
  {
    title: 'Fair use',
    slug: '/policy/acceptable-use',
    titleLong: 'Acceptable Use Policy'
  },
  {
    title: 'Support',
    slug: '/support',
    titleLong: 'Support'
  },
]

useSchemaOrg([
{
  "@context": "http://schema.org",
  "@type": "SoftwareApplication",
  "image": "https://media.drumrudiments.app/df809bcb-1a12-4159-9edb-dc07b8927ae0/drum-rudiments-logo-blue.png",
  "name": "Drum Rudiments app",
  "applicationCategory": "Music Education",
  "operatingSystem": "MULTI",
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "5",
    "reviewCount": "18"
  },
  "offers": {
    "@type": "Offer",
    "price": "5",
    "priceCurrency": "USD"
  }
}
])

</script>